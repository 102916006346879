import '@usb-shield/react-grid/dist/library/styles/index.css'
import ExploreApiView from './DisplayViews/ExploreApiView'
import GetStartedView from './DisplayViews/GetStartedView'
import SupportView from './DisplayViews/SupportView'
import { TextBlockWithIconParts } from './textBlockWithIcon.model'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'

const threeUpContentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4,
  },
}

const contentBlock = threeUpContentBlock

const TextBlockWithIcon = ({
  title,
  shortDescription,
  ctaText,
  ctaLink,
  linkAsButton,
  iconImage,
  displayView,
  levelDownHeads,
  levelUpHeads,
}: TextBlockWithIconParts) => {
  let renderDisplayView: any = '',
    viewData: any = ''
  switch (displayView) {
    case 'supportView':
      viewData = {
        title: title,
        shortDescription: shortDescription,
        ctaText: ctaText,
        ctaLink: ctaLink,
        iconImage: iconImage,
        displayView: displayView,
        linkAsButton: linkAsButton,
        levelDownHeads,
        levelUpHeads,
      }

      renderDisplayView = <SupportView {...viewData}></SupportView>

      break

    case 'getStartedView':
      viewData = {
        title: title,
        shortDescription: shortDescription,
        ctaText: ctaText,
        ctaLink: ctaLink,
        iconImage: iconImage,
        displayView: displayView,
        linkAsButton: linkAsButton,
      }

      renderDisplayView = <GetStartedView {...viewData}></GetStartedView>

      break

    case 'ExploreApiView':
      viewData = {
        title: title,
        shortDescription: shortDescription,
        ctaText: ctaText,
        ctaLink: ctaLink,
        iconImage: iconImage,
        displayView: displayView,
        linkAsButton: linkAsButton,
        levelDownHeads,
        levelUpHeads,
      }

      renderDisplayView = <ExploreApiView {...viewData}></ExploreApiView>

      break

    default:
  }

  return <>{renderDisplayView}</>
}

export default TextBlockWithIcon
