import Brightcove from '@/components/video/brightcove/Brightcove'
import { GetOverviewImageParams } from '@/components/SolutionsAreaOverview/SolutionsAreaOverview.model'
import saoStyle from '@/components/SolutionsAreaOverview/solutionsAreaOverview.module.scss'

export function getOverviewImage(params: GetOverviewImageParams) {
  let img = (
    <img
      id="solution-area-overview-img"
      src={params.imageSrc}
      alt=""
      data-testid="imageDesktopview"
      className={saoStyle.overviewImageDesktop}
    />
  )

  if (params.isPageWide) {
    img = (
      <img
        id="solution-area-overview-img"
        src={params.imageSrc}
        alt=""
        data-testid="imageTabletview"
        className={saoStyle.overviewImageTablet}
      />
    )
  }

  if (params.isMobileDevice) {
    img = (
      <img
        id="solution-area-overview-img"
        src={params.imageSrc}
        alt=""
        data-testid="imageMobileview"
        className={saoStyle.overviewImageMobile}
      />
    )
  }

  if (params.videoSrc) {
    img = <Brightcove videoSrc={params.videoSrc}></Brightcove>
  }

  return img
}
