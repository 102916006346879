const Brightcove = ({ videoSrc }: { videoSrc: string }) => {
  return (
    <iframe
      src={videoSrc}
      className="brightcove-video"
      allowFullScreen
      allow="encrypted-media"
      role="none"
    ></iframe>
  )
}

export default Brightcove
