import '@usb-shield/react-grid/dist/library/styles/index.css'
import '@usb-shield/react-link/dist/library/styles/index.css'
import React from 'react'
import { SolutionsAreaOverviewModel } from '@/components/SolutionsAreaOverview/SolutionsAreaOverview.model'
import SolutionsImageRight from './DisplayViews/SolutionsImageRight'
import SolutionsImageLeftHorizText from './DisplayViews/SolutionsImageLeftHorizText'
import SolutionsImageLeftVertText from './DisplayViews/SolutionsImageLeftVertText'

const SolutionsAreaOverview = ({
  solutionArea,
}: {
  solutionArea: SolutionsAreaOverviewModel
}) => {
  let renderDisplayView: any = ''
  let viewData: any = ''

  let displayView = solutionArea.displayView
  // temporary fix for usbank homepage until displayView field is added in AEM prod

  if (!displayView) {
    displayView = 'SolutionsImageLeftHorizText'
  }
  //////////end temp fix for displayView

  switch (displayView) {
    case 'SolutionsImageRight':
      viewData = {
        solutionArea,
      }

      renderDisplayView = (
        <SolutionsImageRight {...viewData}></SolutionsImageRight>
      )
      break

    case 'SolutionsImageLeftHorizText':
      viewData = {
        solutionArea,
      }

      renderDisplayView = (
        <SolutionsImageLeftHorizText
          {...viewData}
        ></SolutionsImageLeftHorizText>
      )
      break

    case 'SolutionsImageLeftVertText':
      viewData = {
        solutionArea,
      }

      renderDisplayView = (
        <SolutionsImageLeftVertText {...viewData}></SolutionsImageLeftVertText>
      )
      break
  }

  return <>{renderDisplayView}</>
}

export default SolutionsAreaOverview
