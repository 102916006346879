import { ExploreApiData } from './displayViews.model'
import Image from 'next/image'
import styles from './displayViews.module.scss'

const ExploreApiView = ({
  title,
  shortDescription,
  iconImage,
  levelDownHeads,
  levelUpHeads,
}: ExploreApiData) => {
  return (
    <>
      <div className={styles.eafWrapper}>
        <div className={styles.eapiImageWrapper}>
          <img
            alt=""
            src={iconImage}
            data-testid={`iconImage`}
          />
        </div>

        <div className={styles.eafContentWrapper}>
          {levelUpHeads ? (
            <h2 className={styles.headH2Eaf} data-testid={`title`}>
              {title}
            </h2>
          ) : levelDownHeads ? (
            <h4 className={styles.headH2Eaf} data-testid={`title`}>
              {title}
            </h4>
          ) : (
            <h3 className={styles.headH2Eaf} data-testid={`title`}>
              {title}
            </h3>
          )}

          <div
            className={styles.eafParagraph}
            dangerouslySetInnerHTML={{ __html: shortDescription }}
            data-testid={`paragraph`}
          />
        </div>
      </div>
    </>
  )
}

export default ExploreApiView
