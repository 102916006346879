import Image from 'next/image'
import { SupportBlockData } from './displayViews.model'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import { USBColumn } from '@usb-shield/react-grid'
import styles from './displayViews.module.scss'

const contentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4,
  },
}

const SupportView = ({
  title,
  shortDescription,
  iconImage,
  levelDownHeads,
  levelUpHeads,
}: SupportBlockData) => {
  return (
    <>
      <USBColumn layoutOpts={contentBlock} addClasses={styles.sbWrapper}>
        <div className={styles.sbImageWrapper}>
          <img
            src={iconImage}
            alt=""
            width={384}
            height={208}
            data-testid={`iconImage`}
          />
        </div>
        <div className={styles.sbContentWrapper}>
          {levelUpHeads ? (
            <h2 className={styles.headH3} data-testid={`headH3`}>
              {title}
            </h2>
          ) : levelDownHeads ? (
            <h4 className={styles.headH3} data-testid={`headH3`}>
              {title}
            </h4>
          ) : (
            <h3 className={styles.headH3} data-testid={`headH3`}>
              {title}
            </h3>
          )}
          <div
            className={styles.paragraph}
            dangerouslySetInnerHTML={{ __html: shortDescription }}
            data-testid={`paragraph`}
          />
        </div>
      </USBColumn>
    </>
  )
}

export default SupportView
